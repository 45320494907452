<template>
  <div class="container sat-list">
    <SatMenu active="5" />
    <div class="sat-header">
      <PageTitle>
        <div>
          {{ $t("toefl.index.title_new") }}
        </div>
      </PageTitle>
    </div>
    <hr />
    <el-table
      :data="tests"
      border
      style="width: 100%;margin-bottom:30px"
      class="tests-list"
      height="500"
    >
      <el-table-column label="Test Name" width="200">
        <template slot-scope="scope">
          <router-link
            v-if="scope.row.user_exam_id > 0"
            :to="{
              name: 'TestResult',
              query: {
                user_exam_id: scope.row.user_exam_id
              },
              params: { testType }
            }"
            tag="a"

          >
            {{ scope.row.full_name }}
          </router-link>
          <span v-else>
            {{ scope.row.full_name }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Test ID" width="200">
        <template slot-scope="scope">
          <router-link
            v-if="scope.row.user_exam_id > 0"
            :to="{
              name: 'TestResult',
              query: {
                user_exam_id: scope.row.user_exam_id
              },
              params: { testType }
            }"
            tag="a"
          >
            {{ scope.row.title }}
          </router-link>
          <span v-else>
            {{ scope.row.title }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Last Test">
        <el-table-column label="Student" width="">
          <template slot-scope="scope">
            <router-link
              :to="{ name: 'SatTestResults' }"
              v-if="scope.row.user_exam_id > 0"
            >
              {{
                userMethods.displayName(
                  scope.row.first_name,
                  scope.row.last_name
                )
              }}
            </router-link>
            <span v-else>
              N/A
            </span>
          </template>
        </el-table-column>
        <el-table-column label="Date" width="">
          <template slot-scope="scope">
            <span v-if="scope.row.user_exam_id > 0">
              <router-link :to="{ name: 'SatTestResults' }">
                {{ instant.formatDateTime(scope.row.date) }}
              </router-link>
            </span>
            <span v-else>
              N/A
            </span>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="Students" width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.student_test_count > 0">
            <router-link :to="{ name: 'SatTestResults' }">
              <b>{{ scope.row.student_test_count }}</b>
            </router-link>
          </span>
          <span v-else>
            N/A
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Analysis" width="100">
        <template slot-scope="scope">
          <router-link
            v-if="scope.row.exam_id && scope.row.student_test_count > 0"
            :to="{
              path: '/analysis/' + testType,
              query: {
                type: testType,
                test: scope.row[testType + '_id']
              }
            }"
            tag="a"
            style="margin-right:5px"
          >
            <i class="fas fa-chart-bar"></i>
          </router-link>
          <span v-else>
            N/A
          </span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="Action" width="150">
        <template slot-scope="scope">
          <router-link class="action-icon" :to="{ name: 'Examination' }">
            <i class="fa fa-eye" />
          </router-link>
          <a
            class="action-icon"
            :href="
              '/' +
                testType +
                '/answers?exam_id=' +
                scope.row[testType + '_id'] +
                '&can_edit=1'
            "
          >
            <i class="fa fa-edit"></i>
          </a>
          <el-tooltip
            class="item"
            content="批改"
            placement="top"
            popper-class="tooltipColor"
          >
            <a
              class="action-icon"
              :href="`/tests/grading/sat?keyboard=2&exam_id=${scope.row.exam_id}`"
            >
              <i class="fa fa-superscript"></i>
            </a>
          </el-tooltip>
          <el-tooltip
            class="item"
            content="刪除"
            placement="top"
            popper-class="tooltipColor"
          >
            <span>
              <i class="fas fa-trash-alt warning-icon" />
            </span>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { user, instant } from "@ivy-way/material";
import satApi from "@/apis/sat.js";
import PageTitle from "@/components/PageTitle.vue";
import SatMenu from "@/views/components/SatMenu.vue";

export default {
  metaInfo() {
    return {
      title: this.CompanyName
    };
  },
  components: {
    PageTitle,
    SatMenu
  },
  data() {
    return {
      tests: []
    };
  },
  computed: {
    userMethods() {
      return user;
    },
    instant() {
      return instant;
    },
    testType() {
      return "sat";
    }
  },
  async mounted() {
    this.tests = await satApi.getStaticSatTests();
  }
};
</script>
<style scoped>
.sat-header {
  height: 50px;
  padding: 0 20px;
  border-radius: 5px;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.sat-list {
  margin-bottom: 10px;
}

.toefl-link {
  font-size: 14px;
}
@media screen and (max-width: 836px) {
  .sat-header {
    display: block;
    width: 100%;
    height: auto;
    padding: 0;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 736px) {
  .toefl-link {
    margin-top: 10px;
    font-size: 14px;
    display: block;
  }
  .change-version {
    display: flex;
  }
  ::v-deep .change-version .el-button {
    width: auto;
  }
}
@media screen and (max-width: 400px) {
  ::v-deep .change-version .el-button {
    width: 100%;
  }
}
</style>
