<template>
  <div>
    <el-button type="success">
      Add Questions
    </el-button>
    <el-menu :default-active="active" class="el-menu-demo" mode="horizontal">
      <el-menu-item index="1" class="menu-item"
        ><b
          ><router-link :to="{ name: 'LandingPage' }" class="linkInTab">{{
            $t("toefl.Practices")
          }}</router-link></b
        ></el-menu-item
      >
      <el-menu-item index="2" class="menu-item"
        ><b
          ><router-link :to="{ name: 'SatTestResults' }" class="linkInTab">
            {{ $t("toefl.Results") }}</router-link
          ></b
        ></el-menu-item
      >
      <el-menu-item v-if="isAdmin" index="4" class="menu-item">
        <b
          ><router-link :to="{ name: 'ToeflAnalytics' }" class="linkInTab">
            {{ $t("header.analytics") }}
          </router-link></b
        >
      </el-menu-item>
      <el-menu-item v-if="isAdmin" index="5" class="menu-item">
        <b
          ><router-link :to="{ name: 'Browse' }" class="linkInTab">
            {{ $t("header.Browse") }}
          </router-link></b
        >
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
import TOEFL from "@/apis/sat.js";
import role from "@/mixins/role.js";

export default {
  mixins: [role],
  props: ["active"],
  data() {
    return {
      total: 0
    };
  },
  computed: {
    isAdmin() {
      let isAdmin = this.isRoleAdmin();
      return isAdmin;
    }
  },
  watch: {},

  mounted() {
    if (this.isRoleAdmin()) {
      this.getNotification();
    }
  },

  methods: {
    async getNotification() {
      const res = await TOEFL.getNotification();
      this.total = res.total;
    }
  }
};
</script>

<style scoped>
.item >>> .el-badge__content.is-fixed {
  transform: translateX(100%);
}
.item >>> .el-badge__content {
  line-height: 16px;
}

.linkInTab {
  color: inherit !important;
}

.linkInTab::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
}

.linkInTab:hover {
  text-decoration: none;
}

.menu-item {
  position: relative;
}
</style>
